<template>
  <AlertModal
    :show="showSubmissionModal"
    modalTitle="Inquiry Received"
    :modalDescription="`Your inquiry has been submitted to ${currentOffer?.affiliate_name}. They will review, and if your offer is approved, this inquiry will be coverted to an order, where you can submit any necessary practice documentation.`"
    @clickDismiss="routeAfterModal"
  />
  <slot-modal :open="showTermsModal" @closeModal="showTermsModal = false">
    <template #content>
      <div
        class="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
      >
        <view-offer-terms :offerId="offerId" />
      </div>
    </template>
    <template #buttons>
      <base-button
        buttonText="Cancel"
        :redButton="true"
        @buttonClick="cancelAgreeToTerms"
      />
      <base-button
        buttonText="Agree"
        :marginLeft="true"
        @buttonClick="agreeToTerms"
      />
    </template>
  </slot-modal>
  <page-card headingTitle="View Offer to Buy">
    <template #buttonArea>
      <base-button
        helpText="In 'Application' enter a quantity and confirm the terms to submit an inquiry"
        buttonText="Submit"
        @buttonClick="openOfferInquiry"
        :disabled="!inquiryQuantity || !termsConfirmed"
      />
      <base-button
        buttonText="Back"
        @buttonClick="exitOffer"
        :redButton="true"
      />
    </template>
    <template #cardDetails>
      <!-- <text-input
        v-if="currentOffer"
        inputTitle="Buyer"
        :display-only="true"
        :display-only-value="currentOffer?.affiliate_name"
        :wideItem="true"
      />
      <text-input
        v-if="currentOfferDetails?.contract_name"
        inputTitle="Contract Name"
        :display-only="true"
        :display-only-value="currentOfferDetails?.contract_name"
        :wideItem="true"
      /> -->
      <div class="sm:col-span-6">
        <detail-card
          :title="'Buyer'"
          :titleStat="currentOffer?.affiliate_name"
          :primaryStat="{
            text: 'Purchasing: ' + currentOffer?.crop,
            icon: 'ClipboardIcon',
          }"
          :secondaryStats="[
            {
              text: 'Incentives: ' + summarizedPremiums,
              icon: 'CurrencyDollarIcon',
            },
            {
              text: 'Deliver by ' + currentOffer?.deliver_by,
              icon: 'CalendarIcon',
            },
          ]"
        />
      </div>
    </template>
    <template #componentSection>
      <TabGroup as="div" :selectedIndex="selectedTab" @change="tabChanged">
        <div class="border-b border-gray-200">
          <TabList class="-mb-px flex space-x-8">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Application
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Details
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Premiums
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Territory
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  selected
                    ? 'border-indigo-600 text-indigo-600'
                    : 'border-transparent text-gray-700 hover:border-gray-300 hover:text-gray-800',
                  'whitespace-nowrap border-b-2 py-6 text-sm font-medium',
                ]"
              >
                Attachments
              </button>
            </Tab>
          </TabList>
        </div>
        <TabPanels as="template">
          <!-- <TabPanel as="dl" class="pt-10">
            <div class="-ml-6 sm:col-span-6">
              <offer-details :offerId="offerId" />
            </div>
            <p class="my-4">Inquiries</p>
            <inquiry-table :offerId="offerId" />
            <p class="my-4">Orders</p>
            <orders-table :offerId="offerId" />
          </TabPanel> -->
          <TabPanel as="dl" class="pt-10">
            <div
              class="my-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
            >
              Complete and submit this form to apply to the program
            </div>
            <div
              class="mt-2 grid grid-cols-1 gap-x-4 gap-y-8 sm:col-span-6 sm:grid-cols-6"
            >
              <text-input
                :wideItem="true"
                inputTitle="Maximum Sale Quantity"
                inputHtmlType="text"
                :required="true"
                :displayOnly="true"
                :displayOnlyValue="
                  currentOffer?.quantity?.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                  }) +
                  ' ' +
                  currentOffer?.quantity_units
                "
              />
              <div class="hidden sm:col-span-3" />
              <number-formatted-input
                :wideItem="true"
                inputTitle="Quantity You'd Like to Sell'"
                inputHtmlType="text"
                :required="true"
                v-model="inquiryQuantity"
              />
              <select-dropdown
                :wideItem="true"
                inputTitle="Premium"
                :required="true"
                :selectOptions="premiumOptions"
                v-model="inquiryPremium"
              />
              <text-input
                :wideItem="true"
                :displayOnly="true"
                :displayOnlyValue="selectedFieldNames"
                inputTitle="Selected Fields"
                help-text="Selecting a field will allocate it to this order. You can select multiple fields."
              />
              <div class="sm:col-span-6">
                <BaseButton
                  helpText="You must view the terms of acceptance before submitting an offer."
                  buttonText="View Terms"
                  @buttonClick="viewTerms"
                />
              </div>
              <div class="sm:col-span-6">
                <CheckboxInput
                  help-title="Terms of Acceptance"
                  help-text="Please review the terms of acceptance before confirming"
                  :wideItem="true"
                  inputTitle="By checking this box and submitting my application, I agree to the terms of acceptance as presented above."
                  v-model="termsConfirmed"
                  :displayOnly="!termsViewed"
                  :displayOnlyValue="termsConfirmed"
                  :required="true"
                />
              </div>
              <div class="sm:col-span-6">
                <checkbox-input
                  inputTitle="Select fields that will be allocated to this order"
                  v-model="showFieldSelect"
                />
              </div>
              <div class="sm:col-span-6" v-if="showFieldSelect">
                <table-card>
                  <template #header>
                    <table-head
                      :columns="[
                        { name: 'properties.farm', label: 'Farm' },
                        {
                          name: 'properties.name',
                          label: 'Field',
                          filtered: false,
                        },
                        { name: 'properties.crop', label: 'Crop' },
                        {
                          name: 'properties.details.variety',
                          label: 'Variety',
                        },
                        {
                          name: 'properties.acres',
                          label: 'Acres',
                          filtered: false,
                        },
                      ]"
                      :collection="fields"
                      @update:derived-collection="derivedFields = $event"
                      selectionMode="Multiple"
                      @update:selectedCollection="selectedFields = $event"
                      ref="fieldsTable"
                    >
                      <template #toolbarButtons>
                        <div class="hidden sm:flex">
                          <table-toolbar-button
                            buttonText="Clear Selected Fields"
                            :usePlusIcon="true"
                            @buttonClick="clearSelectedFields"
                          />
                        </div>
                      </template>
                    </table-head>
                  </template>
                  <tbody>
                    <tr v-for="field in derivedFields" :key="field">
                      <table-data td-label="Selected">
                        <input
                          type="checkbox"
                          v-model="field.selected"
                          class="cursor-pointer rounded-md border-gray-400 text-indigo-600"
                          @change="toggleFieldInSelectedFields(field)"
                        />
                      </table-data>
                      <table-data td-label="Farm">
                        {{ field.properties.farm }}
                      </table-data>
                      <table-data td-label="Field">
                        <div class="flex">
                          <field-thumbnail
                            :title="field.properties.name"
                            :geojson="field"
                            class="mr-6"
                          ></field-thumbnail>
                          {{ field.properties.name }}
                        </div>
                      </table-data>
                      <table-data td-label="Crop">
                        {{ field.properties.crop }}
                      </table-data>
                      <table-data td-label="Variety">
                        {{
                          field.properties?.details?.variety
                            ? field.properties?.details?.variety
                            : ""
                        }}
                      </table-data>
                      <table-data td-label="Acres" class="text-right">
                        {{
                          field.properties.acres?.toLocaleString(undefined, {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                          })
                        }}
                      </table-data>
                    </tr>
                  </tbody>
                </table-card>
              </div>
            </div>
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <contract-form-details
              v-if="readyToReadOffer"
              v-model="currentOfferDetails"
              :readOnly="true"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <contract-form-premiums
              v-if="readyToReadOffer"
              v-model="currentOfferPremiums"
              :cropType="currentOfferDetails.crop"
              :readOnly="true"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <contract-form-territory
              v-if="readyToReadOffer"
              v-model="currentOfferTerritory"
              :readOnly="true"
            />
          </TabPanel>
          <TabPanel as="dl" class="pt-10">
            <view-attachments-table
              v-if="readyToReadOffer"
              :attachments="currentOffer.attachments"
            />
          </TabPanel>
        </TabPanels>
      </TabGroup>
    </template>
  </page-card>
</template>

<script>
import PageCard from "@/components/cards/PageCard.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SlotModal from "@/components/modals/SlotModal.vue";
import AlertModal from "@/components/modals/AlertModal.vue";
// import OfferForm from "@/layouts/grower-portal/sell/offers/OfferForm.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberFormattedInput from "@/components/form/NumberFormattedInput.vue";
// import { PaperAirplaneIcon } from "@heroicons/vue/outline";
import { useToast } from "vue-toastification";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import { Tab, TabList, TabPanel, TabPanels, TabGroup } from "@headlessui/vue";
import ContractFormDetails from "@/layouts/common/contracts/ContractFormDetails.vue";
import ContractFormPremiums from "@/layouts/common/contracts/ContractFormPremiums.vue";
import ContractFormTerritory from "@/layouts/common/contracts/ContractFormTerritory.vue";
import FieldThumbnail from "@/components/map/FieldThumbnail.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableCard from "@/components/table/TableCard.vue";
import TableData from "@/components/table/TableData.vue";
import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
import DetailCard from "@/components/cards/DetailCard.vue";
import { summarizePremium } from "@/components/composables/scriptUtils";
import ViewAttachmentsTable from "@/layouts/common/attachments/ViewAttachmentsTable.vue";
import ViewOfferTerms from "@/layouts/grower-portal/sell/offers/ViewOfferTerms.vue";
import SelectDropdown from "@/components/form/SelectDropdown.vue";

export default {
  components: {
    PageCard,
    BaseButton,
    SlotModal,
    AlertModal,
    // OfferForm,
    TextInput,
    NumberFormattedInput,
    // PaperAirplaneIcon,
    CheckboxInput,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    TabGroup,
    ContractFormDetails,
    ContractFormPremiums,
    ContractFormTerritory,
    FieldThumbnail,
    TableHead,
    TableCard,
    TableData,
    TableToolbarButton,
    DetailCard,
    ViewAttachmentsTable,
    ViewOfferTerms,
    SelectDropdown,
  },
  data() {
    let selectedTab = 0;
    if (this.$route.query.tab) {
      selectedTab = Number(this.$route.query.tab);
    }
    return {
      inquiryQuantity: null,
      inquiryPremium: null,
      toast: useToast(),
      // Should we only allow them to submit an inquiry if the terms are confirmed?
      showTermsModal: false,
      showSubmissionModal: false,
      termsViewed: false,
      termsConfirmed: false,
      currentOfferDetails: {},
      currentOfferPremiums: [],
      currentOfferTerritory: {},
      derivedFields: [],
      selectedFields: [],
      showFieldSelect: true,
      selectedTab,
    };
  },
  computed: {
    offerId() {
      return this.$route.params.offerId;
    },
    currentOffer() {
      return this.$store.getters.getGrowerMarketOfferById(this.offerId);
    },
    readyToReadOffer() {
      if (
        this.offerId &&
        this.$store.getters.getGrowerMarketOfferById(this.offerId)
      ) {
        return true;
      } else {
        return false;
      }
    },
    fields() {
      return this.$store.getters.getAllFields;
    },
    selectedFieldNames() {
      return this.selectedFields
        .map((field) => field.properties.name)
        .join(", ");
    },
    summarizedPremiums() {
      if (!this.readyToReadOffer) {
        return "";
      }
      return this.summarizePremiums(this.currentOffer.premiums).join(", ");
    },
    premiumOptions() {
      return this.currentOffer.premiums.map((p) => {
        return {
          label:
            summarizePremium(p) +
            " - " +
            p.validation_records.length +
            " requirement(s)",
          value: p.id,
        };
      });
    },
  },
  methods: {
    summarizePremiums(premiums) {
      return premiums.map((p) => {
        return summarizePremium(p);
      });
    },
    async openOfferInquiry() {
      if (!this.currentOffer) {
        await this.$store.dispatch("getGrowerMarketOffers");
      }
      // console.log("openOfferInquiry", this.currentOffer);
      if (this.inquiryQuantity === null) {
        this.toast.error("Please enter a quantity");
        return;
      }
      if (!this.termsConfirmed) {
        this.toast.error("Please confirm the terms");
        return;
      }
      if (!this.inquiryPremium) {
        this.toast.error("Please select a premium");
        return;
      }
      let newThreadId = await this.$store.dispatch("createThread", {
        partner: this.currentOffer.affiliate_org,
        subject: "Order Discussion ",
      });
      // console.log("newThreadId", newThreadId);
      let order = await this.$store.dispatch("createGrowerCropOrder", {
        offer: parseInt(this.offerId),
        quantity: this.inquiryQuantity,
        units: this.currentOffer.quantity_units,
        terms:
          // this.inquiryQuantity +
          // " " +
          // this.currentOffer.quantity_units +
          // " of " +
          // this.currentOffer.crop +
          // ". Additional Details: " +
          this.currentOffer.contract_description,
        premium: this.inquiryPremium,
        details: {
          threadId: newThreadId,
          crop: this.currentOffer.crop,
        },
      });
      if (this.termsConfirmed) {
        await this.$store.dispatch("updateGrowerCropOrder", {
          id: order.id,
          grower_signature: true,
        });
      }
      // Create crop allocations against the selected fields for the order
      for (let field of this.selectedFields) {
        this.$store.dispatch("createGrowerAllocation", {
          crop_order: order.id,
          crop: field.properties.current_crop.id,
          acres: 0,
          notes: "Allocation created on the offer's inquiry submission",
        });
      }
      let messageContent =
        " We are interested in providing " +
        this.inquiryQuantity.toString() +
        " " +
        this.currentOffer.quantity_units +
        " of " +
        this.currentOffer.crop;
      let message = {
        content: messageContent,
      };
      this.$store.dispatch("createMessage", {
        threadId: newThreadId,
        message,
      });
      // Route to orders
      this.showSubmissionModal = true;
    },
    routeAfterModal() {
      this.showSubmissionModal = false;
      this.$router.push({ name: "grower-portal-produce-inquiries" });
    },
    exitOffer() {
      this.$router.push({ name: "grower-portal-market-offers" });
    },
    readOffer() {
      if (!this.readyToReadOffer) {
        return null;
      }
      // set current contract details, premiums, and territory
      let currentOffer = this.$store.getters.getGrowerMarketOfferById(
        this.offerId,
      );
      this.currentOfferDetails = { ...currentOffer };
      this.currentOfferPremiums = currentOffer.premiums;
      this.currentOfferTerritory = currentOffer.territory;
      delete this.currentOfferDetails.premiums;
      delete this.currentOfferDetails.territory;
      this.selectedFields = [];
    },
    tabChanged(index) {
      // console.log("tabChanged", index);
      this.selectedTab = index;
      this.$router.push({
        query: { tab: index },
      });
    },
    // this method should set the termsViewed to true and open a new tab with the terms
    viewTerms() {
      this.termsViewed = true;
      // open a new tab with the terms
      this.showTermsModal = true;
    },
    agreeToTerms() {
      this.termsConfirmed = true;
      this.showTermsModal = false;
    },
    cancelAgreeToTerms() {
      this.termsConfirmed = false;
      this.showTermsModal = false;
    },
    clearSelectedFields() {
      this.selectedFields = [];
      this.$refs.fieldsTable.clearSelected();
    },
    toggleFieldInSelectedFields(field) {
      let index = this.selectedFields.findIndex((f) => f.id === field.id);
      if (index === -1 && field.selected) {
        this.selectedFields.push(field);
      } else if (index !== -1 && !field.selected) {
        this.selectedFields.splice(index, 1);
      }
    },
  },
  watch: {
    offerId: {
      // handler(value) or handler(newValue, oldValue)
      handler() {
        this.readOffer();
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    readyToReadOffer: {
      handler(value) {
        if (value && this.offerId) {
          this.readOffer();
        }
      },
      immediate: true,
    },
  },
};
</script>
