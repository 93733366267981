<template>
  <div
    class="my-2 text-left text-lg font-medium uppercase tracking-wider text-gray-500 sm:col-span-6"
  >
    Applied Records
  </div>
  <div class="sm:col-span-6">
    <table-card v-if="dataIsLoaded && allocations && showFieldSelect">
      <template #header>
        <table-head
          :columns="columns"
          :collection="tableRows"
          :defaultSort="defaultSort"
          @update:derived-collection="derivedRows = $event"
          :toolbar="2"
        >
        </table-head>
      </template>

      <tbody>
        <tr v-for="row in derivedRows" :key="row">
          <table-data td-label="Field">
            <!-- <span v-if="idx == 0">{{ allo.field_name }}</span> -->
            {{ row.field_name }}
          </table-data>
          <table-data td-label="Category">
            {{ row.category }}
          </table-data>
          <template v-for="year in row.years" :key="year">
            <table-data td-label="year.name">
              <span
                :class="
                  year.count == 0 ? 'bg-red-200 px-4' : 'bg-green-200 px-4'
                "
                @click="addRecord(row, year)"
              >
                {{ year.count }}</span
              >
            </table-data>
          </template>
        </tr>
      </tbody>
    </table-card>
  </div>
</template>

<script>
// import PageCard from "@/components/cards/PageCard.vue";
// import BaseButton from "@/components/buttons/BaseButton.vue";

// import StatsRow from "@/components/cards/StatsRow.vue";
// import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/vue";
import TableCard from "@/components/table/TableCard.vue";
import TableHead from "@/components/table/TableHead.vue";
import TableData from "@/components/table/TableData.vue";
// import TableToolbarButton from "@/components/table/TableToolbarButton.vue";
// import OutlineHeroIcon from "@/components/icons/OutlineHeroIcon.vue";
// import SelectDropdown from "@/components/form/SelectDropdown.vue";
// import RecordFormLayout from "@/layouts/grower-portal/records/RecordFormLayout.vue";
// import SlotModal from "@/components/modals/SlotModal.vue";
// import ToggleButton from "@/components/buttons/ToggleButton.vue";
// import FieldValidationTable from "@/pages/grower-portal/sell/produce/orders/FieldValidationTable.vue";
import { useToast } from "vue-toastification";
// import {
//   getDisplayYear,
//   getShortDisplayDate,
//   getLocalTimeFromUTC,
//   // getUTCFromLocalTime,
// } from "@/components/composables/dateUtils.js";

import { interpretPremium } from "@/components/composables/growerPremiumUtils.js";

export default {
  emits: ["selectRecords"],
  components: {
    // ToggleButton,
    TableCard,
    TableHead,
    TableData,
  },
  data() {
    return {
      toast: useToast(),
      selectedTab: parseInt(this.$route.query.tab) || 0,
      defaultSort: { field_name: "asc" },
      derivedRows: [],
      currentPremiumId: parseInt(this.$route.query.premiumId) || 0,
      currentAllocationId: parseInt(this.$route.query.allocationId) || null,
      currentValidationRecordId: null,
      derivedCrops: [],
      selectedCrops: [],
      workingRecord: {
        completed_at: null,
        result: null,
        payload: {},
      },
      // state for tracking recently added records
      recentlyAddedRecords: {},
      // View Record
      modalIsActive: false,
      viewRecord: null,
      // Validate fields
      showFieldSelect: true,
    };
  },
  computed: {
    dataIsLoaded() {
      if (
        this.orderId &&
        this.$store.getters.getGrowerCropOrderById(this.orderId) &&
        this.$store.getters.getGrowerAllocationsByOrderId(this.orderId) &&
        this.$store.getters.getGrowerDeliveriesByOrderId(this.orderId)
      ) {
        return true;
      } else {
        return false;
      }
    },

    // CORE DATA
    orderId() {
      return parseInt(this.$route.params.orderId);
    },
    currentOrder() {
      if (!this.dataIsLoaded) {
        return null;
      }
      // use this.orderId to fetch the order
      return this.$store.getters.getGrowerCropOrderById(this.orderId);
    },
    currentOffer() {
      if (!this.dataIsLoaded) {
        return null;
      }
      // use this.orderId to fetch the offer
      return this.$store.getters.getGrowerMarketOfferById(
        this.currentOrder.offer,
      );
    },
    allocatedRecords() {
      if (!this.dataIsLoaded) {
        return null;
      }
      let records = [];
      // use this.orderId to fetch the allocated records
      for (let crop of this.crops) {
        for (let record of crop.records) {
          records.push(record);
        }
      }
      return records;
    },
    columns() {
      let columns = [];
      columns.push({
        name: "field_name",
        label: "Field",
        filtered: true,
        sorted: true,
      });
      columns.push({
        name: "category",
        label: "Category",
        filtered: true,
        sorted: true,
      });
      for (let year of this.contractYears) {
        columns.push({
          name: year.toString(),
          label: year.toString(),
          filtered: false,
          sorted: false,
        });
      }
      return columns;
    },
    validationCategories() {
      if (!this.dataIsLoaded || !this.currentOffer) {
        return [];
      }
      let categories = [];
      for (let prem of this.currentOffer.premiums) {
        for (let record of prem.validation_records) {
          if (!categories.includes(record.category)) {
            categories.push(record.category);
          }
        }
      }
      return categories;
    },
    contractYears() {
      if (!this.dataIsLoaded) {
        return null;
      }
      let offer = this.currentOffer;
      if (!offer) {
        return [];
      }
      let current_year = offer.program_year;
      let prior_years = offer.additional_prior_year_records;
      let first_year = current_year - prior_years;

      let years = [];
      for (let i = 0; i <= prior_years; i++) {
        years.push(first_year + i);
      }
      return years;
    },

    allocations() {
      if (!this.dataIsLoaded) {
        return null;
      }
      // use this.orderId to fetch the allocations
      // for each allocation, attach the crop_data and field_data
      let allocations = this.$store.getters.getGrowerAllocationsByOrderId(
        this.orderId,
      );
      let enhancedAllocations = allocations.map((allo) => {
        return {
          ...allo,

          field_data: this.$store.getters.getFieldByCropId(allo.crop),
          // currentOrderValidations equals allo.validations_met filtered down to validation ids that are in the currentOffer.premiums
          currentOrderValidations: allo.validations_met.filter((val) =>
            this.currentOffer.premiums.map((prem) => prem.id).includes(val),
          ),
          validatedAgainstSelectedPremium: allo.validations_met.includes(
            this.currentPremiumId,
          ),
        };
      });
      for (let allo of enhancedAllocations) {
        allo.field_name = allo?.field_data?.properties?.name;
      }
      return enhancedAllocations;
    },
    crops() {
      if (!this.dataIsLoaded || !this.allocations || !this.currentOffer) {
        return null;
      }
      let fieldIds = [];
      // loop through allocations, use the getFieldIdByCropId getter to get the field id, and push it to fieldIds
      for (let allo of this.allocations) {
        fieldIds.push(this.$store.getters.getFieldIdByCropId(allo.crop));
      }
      // loop through fieldIds, use the getCropsByFieldId getter to get the crops
      // the arguments for the getter are the field id, the current year - this.currentOffer.additional_prior_year_records, and the current year
      let crops = [];
      for (let fieldId of fieldIds) {
        // get the crops for the fieldId, give each crop field_data via the getFieldByCropId getter, and push them to the crops array as a single array
        for (let crop of this.$store.getters.getCropsByFieldId(fieldId)) {
          crops.push({
            ...crop,
            harvest_year: new Date(crop.harvest_range_end).getFullYear(),
            field_data: this.$store.getters.getFieldById(fieldId),
            records: this.$store.getters.getRecordsByCrop(crop.id),
            relatedAllocationId: this.allocations.find(
              (allo) => allo?.field_data?.id == crop?.field,
            )?.id,
            // set validated to true if the related allocation.validations_met includes the currentPremiumId
            validated: this.allocations
              .find((allo) => allo.field_data.id == crop.field)
              ?.validations_met.includes(this.currentPremiumId),
          });
        }
      }
      return crops;
    },
    allocationCrops() {
      if (!this.dataIsLoaded || !this.allocations || !this.crops) {
        return null;
      }
      // only return the crops that have a relatedAllocationId equal to the currentAllocationId
      return this.crops.filter(
        (crop) => crop.relatedAllocationId == this.currentAllocationId,
      );
    },
    //
    // Premiums
    //
    premiumOptions() {
      if (!this.dataIsLoaded || !this.currentOffer?.premiums) {
        return [];
      }
      return this.currentOffer.premiums.map((premium) => ({
        // Put in better premium names
        label: premium.type,
        value: premium.id,
      }));
    },
    currentPremium() {
      if (!this.dataIsLoaded || !this.currentOffer?.premiums) {
        return null;
      }
      // return the premium from this.currentOffer.premiums that matches the currentPremiumId
      return this.currentOffer?.premiums.find(
        (premium) => premium.id == this.currentPremiumId,
      );
    },
    //
    // Validation Records
    //
    currentPremiumValidationRecordOptions() {
      if (!this.dataIsLoaded || !this.currentPremium) {
        return [];
      }
      // map through and return label and value
      return this.currentPremium.validation_records.map((record) => ({
        label: record.category,
        value: record.id,
      }));
    },
    currentValidationRecord() {
      if (!this.dataIsLoaded || !this.currentPremium) {
        return null;
      }
      // return the validation record from this.currentPremium.validation_records that matches the currentValidationRecordId
      return this.currentPremium.validation_records.find(
        (record) => record.id == this.currentValidationRecordId,
      );
    },
    //
    // Allocations
    //
    allocationOptions() {
      if (!this.dataIsLoaded || !this.allocations) {
        return [];
      }
      // for allo in allos, create the label and value where label is the allo.field_data.properties.name name and value is the allocation.id
      return this.allocations.map((allo) => ({
        label: allo?.field_data?.properties?.name,
        value: allo.id,
      }));
    },
    currentAllocation() {
      if (!this.dataIsLoaded || !this.allocations) {
        return null;
      }
      // return the allocation from this.allocations that matches the currentAllocationId
      return this.allocations.find(
        (allo) => allo.id == this.currentAllocationId,
      );
    },
    currentAllocationMatchingTypeRecords() {
      if (
        !this.dataIsLoaded ||
        !this.currentAllocation ||
        !this.currentValidationRecord ||
        !this.crops
      ) {
        return [];
      }
      let records = [];
      // for all the crops in allocationCrops, push the records with the same category as the currentValidationRecord.category
      for (let crop of this.allocationCrops) {
        // attach the crop harvest_year into the record body as crop_harvest_year and push it to the records array if the record category matches the currentValidationRecord.category
        records.push(
          ...crop.records
            .filter(
              (record) =>
                record.category == this.currentValidationRecord.category,
            )
            .map((record) => ({
              ...record,
              crop_harvest_year: crop.harvest_year,
            })),
        );
      }
      return records;
    },
    tableRows() {
      if (!this.dataIsLoaded || !this.allocations || !this.crops) {
        return [];
      }
      let rows = [];
      for (let allo of this.allocations) {
        let newField = true;
        for (let category of this.validationCategories) {
          let row = {};
          if (newField) row.field_name = allo?.field_data?.properties?.name;
          else row.field_name = "";
          row.category = category;
          row.years = [];
          for (let year of this.contractYears) {
            let count = this.allocatedRecords.filter((record) => {
              let crop = this.crops.find((crop) => crop.id == record.crop);
              let matches =
                crop?.field == allo?.field_data?.id &&
                record.category == category &&
                crop.harvest_year == year;
              return matches;
            }).length;
            row.years.push({
              name: year,
              count: count,
            });
          }
          rows.push(row);
          //   }
        }
      }
      return rows;
    },
  },
  methods: {
    addRecord(row, year) {
      console.log("add record", row, year);
      this.$emit("selectRecords", row.category);
    },
    navigateToOrder() {
      this.$router.push({
        name: "grower-portal-offer-order",
        params: { orderId: this.orderId },
      });
    },
    setPremiumId(premiumId) {
      this.currentPremiumId = premiumId;
    },
    setCurrentAllocationId(allocationId) {
      this.currentAllocationId = allocationId;
    },

    async undoRecord(crop) {
      const recordId = this.recentlyAddedRecords[crop.id];
      if (!recordId) return;

      await this.$store.dispatch("deleteRecord", { id: recordId });
      this.toast.success(
        `Record deleted for ${crop.field_data.properties.name}`,
      );
      delete this.recentlyAddedRecords[crop.id]; // Remove the record from tracking
    },
    bulkAddRecords() {
      for (let crop of this.selectedCrops) {
        this.addRecord(crop);
      }
      this.toast.success(`${this.selectedCrops.length} records created`);
    },
    setViewRecord(record) {
      this.viewRecord = record;
      this.modalIsActive = true;
    },
    closeRecordModal() {
      this.modalIsActive = false;
    },
    async deleteRecord(record) {
      await this.$store.dispatch("deleteRecord", record, true);
      this.toast.success("Record deleted");
    },
    setDefaultPremium() {
      if (this.premiumOptions.length > 0) {
        this.currentPremiumId = this.premiumOptions[0].value;
      }
    },
    setDefaultAllocation() {
      if (this.allocationOptions.length > 0) {
        this.currentAllocationId = this.allocationOptions[0].value;
      }
    },
    setDefaultValidationRecord() {
      if (this.currentPremiumValidationRecordOptions.length > 0) {
        this.currentValidationRecordId =
          this.currentPremiumValidationRecordOptions[0].value;
      }
    },
    toggleAllocationValidationAgainstPremium(alloId) {
      if (!this.currentAllocation || !this.currentPremium) {
        return;
      }

      // Fetch the full allocation object from the store
      let allo = this.$store.getters.getGrowerAllocationById(alloId);
      // Clone the validations_met array to avoid mutating the original
      let allo_validations = [...allo.validations_met];

      // Check if the currentPremium.id is already in the validations_met array
      const premiumIndex = allo_validations.indexOf(this.currentPremium.id);

      if (premiumIndex !== -1) {
        // If the currentPremium.id exists in the array, remove it
        allo_validations.splice(premiumIndex, 1);
      } else {
        // If the currentPremium.id doesn't exist in the array, add it
        allo_validations.push(this.currentPremium.id);
      }

      // Dispatch the updated allocation with the modified validations_met array
      this.$store.dispatch("updateGrowerAllocation", {
        ...allo, // Spread the entire allocation object
        validations_met: allo_validations, // Replace the validations_met array
      });
    },
    getAllocationValidatedAgainstCurrentPremium(alloId) {
      if (!this.currentAllocation || !this.currentPremium) {
        return false;
      }
      return this.currentAllocation.validations_met.includes(alloId);
    },
    interpretPremium,
  },
  watch: {
    premiumOptions: {
      handler(newVal, oldVal) {
        if (newVal.length != oldVal.length) {
          this.setDefaultPremium();
        }
      },
    },
    allocationOptions: {
      handler(newVal, oldVal) {
        if (newVal.length != oldVal.length || !this.currentAllocationId) {
          this.setDefaultAllocation();
        }
      },
    },
    // run immediately
    currentPremiumValidationRecordOptions: {
      handler() {
        this.setDefaultValidationRecord();
      },
      // this will cause the handler to be run immediately on component creation
      immediate: true,
    },
    orderId: {
      handler() {
        this.setDefaultPremium();
        this.setDefaultAllocation();
        this.setDefaultValidationRecord();
      },
      immediate: true,
    },
  },
};
</script>
