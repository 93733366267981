<template>
  <div class="mt-6 overflow-hidden bg-white p-4 shadow sm:rounded-md">
    <form @submit.prevent="saveCsv" id="import">
      <div class="flex">
        <file-select
          buttonText="Step 1: Please select a file to import (.csv)"
          @input="setFile"
          accept=".csv"
        />
        <template v-if="csv != null"
          ><check-circle-icon class="h-8 w-8 fill-green-500"
        /></template>
      </div>
      <div class="flex">
        <base-button
          @buttonClick="uploadCsvfile"
          buttonText="Step 2: Upload the file"
          :disabled="csv == null"
        />
        <template v-if="uploaded"
          ><check-circle-icon class="h-8 w-8 fill-green-500"
        /></template>
      </div>
      <div v-if="uploaded">
        <p class="mb-4 mt-4 block text-sm font-bold text-twilight-700">
          Step 3: Map the attributes
        </p>
        <map-attributes
          :attributes="fieldAttributes"
          :options="options"
          v-model="mappings"
        />
        <div class="mt-4">
          <submit-button
            formId="import"
            buttonText="Step 4: Import the fields"
            :disabled="!csv"
          />
        </div>
      </div>
    </form>
  </div>
  <modal :show="showReport" title="Import Report">
    <div v-if="importReport">
      <div v-html="importReport" class="prose"></div>
      <base-button
        @buttonClick="closeReport"
        buttonText="Close"
        :disabled="!uploaded"
      />
    </div>
    <div v-else>Loading data...</div>
  </modal>
</template>

<script>
import { CheckCircleIcon } from "@heroicons/vue/solid";
import FileSelect from "@/components/form/FileSelect.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import SubmitButton from "@/components/buttons/SubmitButton.vue";
import MapAttributes from "@/components/import/MapAttributes.vue";
import Modal from "@/components/modals/PopupModal.vue";
import Papa from "papaparse";

//import L from "leaflet";
import _ from "lodash";

export default {
  components: {
    CheckCircleIcon,
    FileSelect,
    BaseButton,
    SubmitButton,
    MapAttributes,
    Modal,
  },
  props: {
    fieldAttributes: { type: Object },
  },
  data() {
    return {
      csv: null,
      uploaded: false,
      features: [],
      options: [],
      mappings: null,
      addRecords: true,
      updateRecords: true,
      importReport: "",
      showReport: false,
    };
  },
  computed: {},
  methods: {
    closeReport() {
      this.showReport = false;
      // now reset the upload so we can grab another file
      this.csv = null;
      this.uploaded = false;
      this.features = [];
      this.options = [];
      this.mappings = null;
      this.addRecords = true;
      this.updateRecords = true;
      this.importReport = "";
    },
    setFile(file) {
      this.csv = file;
    },
    async uploadCsvfile() {
      let componentHandle = this;
      let features = [];
      let header = [];
      // console.log("uploadCsvfile");

      await Papa.parse(this.csv, {
        complete: function (results) {
          // console.log("parse done", results);
          // console.log("csvArray", results);
          // grab the first column to get column names
          for (let column of results.data[0]) {
            header.push(column);
          }
          // console.log("header", header);
          for (const [i, row] of results.data.entries()) {
            if (i > 0) {
              //skip the header
              let feature = { properties: {} };
              for (let i = 0; i < header.length; i++) {
                feature.properties[header[i]] = row[i];
              }
              features.push(feature);
            }
          }
          // console.log("features uploaded", features);
          componentHandle.features = features;
          componentHandle.setMappingOptions();
          componentHandle.uploaded = true;
        },
      });
    },
    setMappingOptions() {
      // grab first feature in features and use it to get the attributes
      let options = [];
      if (this.features.length > 0) {
        //console.log("mapping feature attributes");
        for (let property in this.features[0].properties) {
          //console.log("property: ", property);
          options.push({
            value: property.toLowerCase(),
            label: property.toLowerCase(),
          });
        }
      }
      // console.log("options", options);
      this.options = options;
    },
    toLowerKeys(obj) {
      return Object.keys(obj).reduce((accumulator, key) => {
        accumulator[key.toLowerCase()] = obj[key];
        return accumulator;
      }, {});
    },
    saveCsv() {
      this.formatAsParcelGeoJSON();
    },
    formatAsParcelGeoJSON() {
      for (let feature of this.features) {
        feature.properties = this.toLowerKeys(feature.properties);
        //console.log("properties", feature.properties);
        for (const [key, { value }] of Object.entries(this.mappings)) {
          if (value) {
            //console.log("mapping", key, label, required, value);
            feature.properties[key] = feature.properties[value];
          }
        }
        let parcelProperties = {};
        let geojsonLayer = null;
        for (const [key, value] of Object.entries(feature.properties)) {
          // console.log("key properties", key, value);
          switch (key) {
            case "wkt": // wkt is the geometry
              // console.log("wkt", value);
              var parse = require("wellknown");
              geojsonLayer = null;
              if (value) {
                geojsonLayer = parse(value); //L.geoJson(parse(value));
                // console.log("geojsonLayer", geojsonLayer);
              }
              break;
            case "name":
            case "farm":
            case "acres":
            case "crop":
              //console.log("primary", key, value);
              parcelProperties[key] = value;
              break;
            case "irrigated":
              if (!parcelProperties.details) parcelProperties.details = {};
              // console.log("irrigated", parcelProperties, key, value);
              parcelProperties.details[key] = value;
              if (
                value?.toLowerCase()?.charAt(0) == "y" ||
                value?.toLowerCase()?.charAt(0) == "x" ||
                value?.toLowerCase()?.charAt(0) == "i"
              )
                parcelProperties.details.irrigated = true;
              else parcelProperties.details.irrigated = false;
              break;
            default: {
              if (!parcelProperties.details) parcelProperties.details = {};
              //console.log("detail", parcelProperties, key, value);
              parcelProperties.details[key] = value;
            }
          }
        }
        //console.log("parcelProperties", parcelProperties);
        if (geojsonLayer) {
          feature.imported_geometry = geojsonLayer; //geojsonLayer.geometry;
        } else feature.imported_geometry = null;
        feature.properties = parcelProperties;
        // console.log("properties post mapping", feature);
      }
      this.saveFields(this.features);
    },

    async saveFields(fieldsSet) {
      this.showReport = true;
      // console.log("saving fields", fieldsSet);
      if (!fieldsSet) return;
      this.importReport = "";
      // let myFields = structuredClone(this.$store.state.fields.fields);
      let successfulUpdates = 0;
      for (const row of fieldsSet) {
        // console.log("processing a row", row);
        let insert = false;
        let currentField = null;
        // check if field exists already
        currentField = this.$store.getters.getFieldByName(
          row.properties.name,
          row.properties.farm,
        );
        if (currentField) {
          insert = false;
        } else {
          insert = true;
        }
        // no match found
        if (insert) {
          if (this.addRecords) {
            //insert a new field
            row.geometry = row.imported_geometry;
            delete row["imported_geometry"]; // get rid of the imported_geometry property it's only temporary
            // console.log("inserting new field", row.properties.name, row);
            if (row.properties.name && row.properties.name.length > 0) {
              this.importReport +=
                "Adding new field " + row.properties.name + "<br>";
              successfulUpdates++;
              // console.log("inserting new field", row);
              await this.$store.dispatch("createField", row);
            } else {
              this.importReport += "No field name, skipping <br>";
            }
          } else
            this.importReport +=
              "No match found for " + row.properties.name + "<br>";
        } else if (currentField && this.updateRecords) {
          if (row.geometry) currentField.geometry = null; // update geometry, don't add them together.
          if (row.properties?.name != currentField.properties?.name) {
            row.properties.name = currentField.properties?.name; // don't update the name, we found it via alias
          }
          let newField = _.merge(structuredClone(currentField), row);
          if (newField.imported_geometry)
            newField.geometry = newField.imported_geometry; // only override geometry if it is coming in with updated data
          delete row["imported_geometry"]; // get rid of the imported_geometry property it's only temporary
          this.importReport += "Updating field " + row.properties.name + "<br>";
          successfulUpdates++;
          // console.log("updating existing field", newField);
          await this.$store.dispatch("updateField", newField);
          // console.log("updated field");
        }
      }
      // console.log("import complete");
      this.importReport +=
        "Successfully updated " + successfulUpdates + " fields";
      this.showReport = true;
    },
  },
};
</script>
